import styled from "styled-components"
import { below } from "../../../config/utilities"
import { rhythm } from "../../../config/typography"

export default styled.div`
  aside[name="head"] {
    position: relative;
    margin: auto;
    width: 100%;
    margin-left: 0;
  }
  h2,
  h3 {
    font-family: Teko;
    text-transform: uppercase;
    font-style: normal;
  }
  form {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .budget {
      display: flex;
      flex-flow: column nowrap;
      justify-content: start;
      align-items: start;
      label {
        margin-right: ${rhythm(1)};
      }
    }
    & > div {
      width: 100%;

      & > label {
        font-family: "Teko";
        text-transform: uppercase;
        text-align: left;
        color: ${props => props.theme.brand.primary};
        font-size: ${rhythm(0.75)};
        margin-top: ${rhythm(0.5)};
        ${below.xs`
        font-size: ${rhythm(0.77)};
        width:100%;
        text-align:center;
      
        `}
      }
      & > input,
      & > select,
      & > textarea {
        width: 100%;
        flex: 1;
        padding: 4px 8px;
        font-size: 1em;
        background-color: transparent;
        border: unset;
        border-bottom: 1px solid ${props => props.theme.colors.primarydark};
        ${below.xs`
          width:100%;
        `}
      }
      & > textarea[name="projectInfo"] {
        flex: 1 auto;
        height: 10em;
        font-family: Raleway;
        font-weight: 500;
        font-size: 1em;
        border: 1px solid ${props => props.theme.brand.primary};
        background-color: ${props => props.theme.colors.litestgrey};
        ${below.xs`
         
        `}
      }

      & > input[type="checkbox"] {
        margin: 0 auto;
      }
      & > div {
        margin: 0 auto;

        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        & > label {
          display: block;

          & > input {
            margin-left: 0.85em;
          }
        }
      }
    }
    & > .buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;

      a {
        margin: 0 !important;
        z-index: 1000;
      }
    }
  }
`
