import React from "react"
import { Container, Row, Col } from "react-grid-system"
import PropTypes from "prop-types"
import { FaPhone, FaEnvelope, FaPaperPlane } from "react-icons/fa"
import SEO from "../components/seo"
import NewContactForm from "../components/contact/NewContactForm"
import { below } from "../../config/utilities"
import { ContactSubhead, LatestTweets, ContactSideHeader } from "../elements"
import { rhythm } from "../../config/typography"
import TransitionContext from "../context/TransitionContext"

const contactItems = [
  {
    key: 1,
    contactTitle: `Email`,
    contactInfo: `info@pakd.media`,
    contactIcon: (
      <a
        href="mailto:info@pakd.media"
        aria-label="email"
        css={`
          width: 100%;
          display: flex;
        `}
        className="contactSide"
      >
        <FaEnvelope listItem color="rgba(44,62,80,.95)" />
        <h5
          css={`
            color: black;
            float: right;
            text-align: left;
          `}
        >
          Email
        </h5>
      </a>
    ),
  },
  {
    key: 2,
    contactTitle: `Phone`,
    contactInfo: `917-727-4141`,
    contactIcon: (
      <a
        href="tel:917-727-4141"
        aria-label="phone-number"
        css={`
          width: 100%;
          display: flex;
        `}
        className="contactSide"
      >
        <FaPhone listItem color="rgba(44,62,80,.95)" />
        <h5
          css={`
            color: black;
            float: right;
            text-align: left;
          `}
        >
          Phone
        </h5>
      </a>
    ),
  },
  {
    key: 3,
    contactTitle: `Mail`,
    contactInfo: `PO BOX 471944 Brooklyn, NY 11247`,
    contactIcon: (
      <>
        <FaPaperPlane
          color="rgba(44,62,80,.95)"
          listItem
          style={{ marginTop: `${rhythm(0.5)}` }}
        />
        <h5
          css={`
            color: black;
            float: right;
            text-align: left;
            padding: ${rhythm(0.25)} 0;
          `}
        >
          PO BOX 471944 <br /> Brooklyn, NY 11247
        </h5>
      </>
    ),
  },
]

const Contact = ({ location }) => (
  <TransitionContext location={location}>
    <SEO
      title="Contact"
      pathname={location.pathname}
      description="Contact the team at PAKD Media to start your next project."
    />
    <Container className="pagemargintop smallmarginbtm">
      <Row justify="center" className="pagemargintopsm">
        <Col md={8} sm={12}>
          <NewContactForm />
        </Col>
        <Col md={4} xs={12}>
          <div
            css={`
              padding-top: ${rhythm(1)};
              padding-left: ${rhythm(1)};
              ${below.s`
              padding-left:0;
              `}
            `}
          >
            <ContactSubhead contactItems={contactItems} titleRow="Contact" />

            <ContactSideHeader titleRow="Tweets" />
            <LatestTweets />
          </div>
        </Col>
      </Row>
    </Container>
  </TransitionContext>
)

Contact.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}
export default Contact
