import React from "react"
import { Form, Field } from "react-final-form"
import styled from "styled-components"
import { ContactButtons } from "../../elements"
import NewStyles from "./NewStyles"

const HeaderWrap1 = styled.div`
  h1 {
    color: ${props => props.theme.brand.primaryalt};
    margin: 0;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background: ${props => props.theme.brand.prpteal};
  }
`
const onSubmit = async () => {}
const NewContactForm = () => (
  <NewStyles>
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: ``,
        companyBrand: ``,
        email: ``,
        budget: ``,
        interestedIn: ``,
        projectInfo: ``,
      }}
      render={({ form }) => (
        <form
          name="contact"
          method="post"
          netlify="true"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/success"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:
              <input name="bot-field" />
            </label>
          </p>
          <HeaderWrap1>
            <h1>Inquiries</h1>
          </HeaderWrap1>
          <div id="first">
            <label htmlFor="name">Name</label>
            <Field
              name="name"
              id="name"
              component="input"
              type="text"
              placeholder="Hi! My name is..."
            />
          </div>
          <div
            style={{
              clear: `both`,
            }}
          >
            <label htmlFor="companyBrand">Company/Brand</label>
            <Field
              name="companyBrand"
              id="companyBrand"
              component="input"
              type="text"
              placeholder="e.g. Dunder Mifflin"
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <Field
              name="email"
              id="email"
              component="input"
              type="text"
              placeholder="example@example.com"
            />
          </div>
          <div>
            <label htmlFor="budget">Budget</label>
            <Field
              name="budget"
              id="budget"
              component="input"
              type="text"
              placeholder="$10K-$15K, $25K+, etc."
            />
          </div>
          <div>
            <label htmlFor="interestedIn">I am interested in...</label>
            <Field
              name="interestedIn"
              id="interestedIn"
              component="input"
              type="text"
              placeholder="Design, branding, UX/UI, animation, etc."
            />
          </div>

          <div>
            <label htmlFor="projectInfo">Notes</label>
            <Field
              name="projectInfo"
              id="projectInfo"
              component="textarea"
              type="text"
              placeholder="I could use PAKD Media's super powers to help with..."
            />
          </div>
          <div className="buttons">
            <ContactButtons secondary form={form}>
              Submit
            </ContactButtons>
            <ContactButtons secondary form={form} reset={form.reset}>
              Reset
            </ContactButtons>
          </div>
        </form>
      )}
    />
  </NewStyles>
)

export default NewContactForm
